'use strict';

function clickAndCollectFunctions() {
    $('#btn-add-to-cart').addClass('d-none');
    $('#inStoreInventoryModal .msg-title').addClass('mb-4');
    $('#inStoreInventoryModal .results p').addClass('d-none');
    $('#inStoreInventoryModal #searchStoreCC').addClass('d-none');
    $('#inStoreInventoryModal .map-container').css('margin-top', 0);
    $('#inStoreInventoryModal .container').css('max-width', 'unset');
    $('#inStoreInventoryModal .cc-container-results').css({
        'align-items': 'center',
        display: 'flex'
    });
    $('#btn-cart-modal-click-and-collect').removeClass('d-none');
    $('#btn-cart-modal-click-and-collect').attr('disabled', false);
    $('#btn-cart-modal-click-and-collect').on('click', function () {
        $('#clickandCollectAlreadyInCartModal').modal('show');
        $('#btn-cart-modal-click-and-collect').attr('disabled', true);
        $('.btn-checkout-container button').attr('disabled', true);
    });

    $('.btn-checkout-container button').on('click', function () {
        $('#clickandCollectAlreadyInCartModal').modal('show');
        $('#btn-cart-modal-click-and-collect').attr('disabled', true);
        $('.btn-checkout-container button').attr('disabled', true);
    });

    $('.close-message').on('click', function () {
        $('#clickandCollectAlreadyInCartModal').modal('hide');
    });

    $('.btn-get-in-store-inventory').on('mouseenter', function () {
        // eslint-disable-next-line no-console
        if ($(this).attr('disabled') === 'disabled') {
            $('.tooltip-clickandcollect.one').css({ display: 'block' });
        }
    });
    $('.btn-get-in-store-inventory').on('mouseleave', function () {
        if ($(this).attr('disabled') === 'disabled') {
            $('.tooltip-clickandcollect.one').css({ display: 'none' });
        }
    });
}

module.exports = {
    clickAndCollectFunctions: clickAndCollectFunctions
};
