'use strict';
var base = require('base/product/base');
var focusHelper = require('base/components/focus');

/**
 *  This function is responsible for updating the "Quiero" buttons,
 *  increasing or decreasing the amount of product required.
 *  Update/user             Description                               Date
 *  Cristian Cortes    Se modifica para que tome 'quantityInput'      05/04/2024
 *                     del nuevo isml que se dividio
 */
function updateQuantityButton(response) {
    // AZ-10527 L&F Quantity START
    var quantityInput = $('input[name$="quickViewQuantity"]');
    var availableCount = parseInt(
        response.product.availableToSell.quantity,
        10
    );

    quantityInput.attr('data-available', availableCount);
    quantityInput.prop('max', availableCount);

    if (quantityInput.val() > availableCount) {
        quantityInput.val(availableCount);
    }

    var currentQuantityValue = parseInt($('.pz-quantity-select2').val(), 10);

    // //Initalize buttons
    $('.quantity-selector')
        .find('.btn-quantity.add')
        .removeClass('btn-qty-disabled');
    $('.quantity-selector')
        .find('.btn-quantity.sub')
        .removeClass('btn-qty-disabled');

    if (response.product.readyToOrder) {
        // Disable plus button on max quantity
        if (availableCount === currentQuantityValue) {
            $('.quantity-selector')
                .find('.btn-quantity.add')
                .addClass('btn-qty-disabled');
        }
        // Disable minus button on min quantity
        if (
            response.product.minOrderQuantity === currentQuantityValue
        ) {
            $('.quantity-selector')
                .find('.btn-quantity.sub')
                .addClass('btn-qty-disabled');
        }
    } else {
        $('.quantity-selector')
            .find('.btn-quantity.add')
            .addClass('btn-qty-disabled');
        $('.quantity-selector')
            .find('.btn-quantity.sub')
            .addClass('btn-qty-disabled');
    }
    // AZ-10527 L&F Quantity END
}

// hotfix/DAB-3712 //

function validateProductOnInit(data) {
    if (data.product.isLimited) {
        if (data.nonPurchasableProducts || data.isLimitedAdded) {
            $('.attribute').addClass('d-none');
            $('.quantity').addClass('d-none');
            $('.load-islimited').addClass('d-none');
            $('.add-to-cart').prop('disabled', true);
            $('.add-to-cart-global').prop('disabled', true);
            $('.load-islimited-added').removeClass('d-none');
        } else if (!data.nonPurchasableProducts && !data.isLimitedAdded) {
            $('.attribute').removeClass('d-none');
            $('.load-islimited').removeClass('d-none');
            $('.load-islimited-added').addClass('d-none');
        }
    } else if (
        (data.isBigVolume && data.basketHasBigVolumeInside) ||
        (data.isBigVolume && data.basketHasNormalVolumeInside) ||
        (!data.isBigVolume && data.basketHasBigVolumeInside)
    ) {
        $('.attribute').addClass('d-none');
        $('.add-to-cart').addClass('d-none');

        $('.add-to-cart').prop('disabled', true);
        $('.add-to-cart-global').prop('disabled', true);
        $('.detail-panel .attributes').addClass('d-none');
    } else {
        $('.attribute').removeClass('d-none');
        $('.isLimited').addClass('d-none');
        $('.load-islimited-added').addClass('d-none');
    }
}

// hotfix/DAB-3712 //

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(productUrl) {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link" href="' + productUrl + '">Ir a la Página de Producto</a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <img class="close-img"/>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body quickView-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

function handleCarouselQuickView() {
    $('.slider-single').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        adaptiveHeight: true,
        infinite: true,
        useTransform: true,
        speed: 400
    });
    $('.slider-nav')
        .on('init', function () {
            $('.slider-nav .slick-slide.slick-current').addClass('is-active');
        }).not('.slick-initialized')
        .slick({
            slidesToShow: 5,
            slidesToScroll: 4,
            arrows: false,
            dots: false,
            focusOnSelect: false,
            infinite: false
        });

    $('.slider-single').on('afterChange', function (event, slick, currentSlide) {
        $('.slider-nav').slick('slickGoTo', currentSlide);
        var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
        $('.slider-nav .slick-slide.is-active').removeClass('is-active');
        $(currrentNavSlideElem).addClass('is-active');
    });

    $('.slider-nav').on('click', '.slick-slide', function (event) {
        event.preventDefault();
        var goToSingleSlide = $(this).data('slick-index');

        $('.slider-single').slick('slickGoTo', goToSingleSlide);
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            validateProductOnInit(data);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            $('#quickViewModal .modal-body .carousel').carousel();
            updateQuantityButton(data);
            handleCarouselQuickView();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            if (e.currentTarget.dataset.target.includes('editWishlistProductModal')) return;
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            // selectedValueUrl = selectedValueUrl.replace('?', 'Cutom?');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement(e.target.dataset.productUrl);
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    /**
     *  Through this function, the activation and deactivation of the "Agregar al Carrito y Comprar Ahora"
     *  buttons of the pdp "recomendados para vos" products modal is controlled, as long as
     *  they section a size.
     */
    botonQuickview: function () {
        $('body').on('click', '.product-quickview .attribute li.variation-attribute-size', function () {
            $('body').on('product:afterAttributeSelect', function (e, response) {
                var $detailPanel = $('.detail-panel');
                var $buttonContainer = $detailPanel.siblings('.button-container');
                var $addToCartButton = $buttonContainer.find('button.add-to-cart-global');
                var $variationSizes = $('.variation-attribute-size');
                // Habilitar o deshabilitar botones según la disponibilidad
                var isReadyToOrder = response.data.product.readyToOrder;
                var currentAvailability = response.data.currentVariationAvailability;
                $variationSizes.each(function () {
                    if ($(this).attr('variation-available') === 'false' && response.data.storeInBasket !== null) {
                        $(this).addClass('disabled');
                    }
                });
                if (currentAvailability && isReadyToOrder) {
                    $addToCartButton.attr('disabled', false);
                } else {
                    $addToCartButton.attr('disabled', !isReadyToOrder);
                }
            });
        });
    },

    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                // $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $(response.container).data(
                    'pid',
                    response.data.product.id
                );
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');
            if (!$(response.$productContainer).hasClass('multiple-recommended-container')) {
                $('.add-to-cart-global', dialog).attr('disabled',
                    !$('.global-availability', dialog).data('ready-to-order')
                    || !$('.global-availability', dialog).data('available')
                );
            } else if ($(response.$productContainer).hasClass('selected')) {
                $(response.$productContainer).find('.select-container-recommendation').prop('disabled', true);
            } else {
                $(response.$productContainer).find('.select-container-recommendation').prop('disabled', false);
            }
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);


            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
            updateQuantityButton(response);
        });
    },
    updateQuantitySelectorMinicart: function () {
        $('body').on('click', '.quantity-for-minicart .quantity-selector .qty>button', function () {
            var $input = $(this)
                .parents('.quantity-selector')
                .find('input.quantityChangeMinicart');
            var val = parseInt($input.val(), 10);

            const min = parseInt($input.attr('data-minimum'), 10);
            const isSetItem = $(this).closest('.set-item').length > 0;
            var available = parseInt($input.attr('data-available'), 10);
            var max = parseInt($input.attr('data-product-limit'), 10);

            if (available < max) {
                max = available;
            }

            if ($(this).hasClass('add-minicart')) {
                setTimeout(() => {
                    if (parseInt($(this).prev().val(), 10) === max) {
                        $(this).addClass('btn-qty-disabled');
                    }
                    if (parseInt($(this).prev().val(), 10) > min) {
                        $(this).prev().prev().removeClass('btn-qty-disabled');
                    }
                }, 500);

                if (val < max) {
                    if (isSetItem && val === 0) {
                        val = min;
                    } else if (min !== 1) {
                        val += min;
                    } else {
                        if (val > 0) {
                            $('.quantityChangeMinicart')
                                .closest('.btn-quantity.sub-minicart')
                                .removeClass('btn-qty-disabled');
                        }
                        val += 1;
                    }
                }
            } else if ($(this).hasClass('sub-minicart')) {
                setTimeout(() => {
                    if (parseInt($(this).next().val(), 10) < max) {
                        $(this).next().next().removeClass('btn-qty-disabled');
                    }
                    if (parseInt($(this).next().val(), 10) === min) {
                        $(this).addClass('btn-qty-disabled');
                    }
                }, 500);
                // let quantity selectors in set items have a minimum of 0
                if (isSetItem && val === min) {
                    val = 0;
                }
                if (min !== 1 && val > min) {
                    val -= min;
                } else if (val > min) {
                    if (val === 2) {
                        $('.quantityChangeMinicart')
                            .closest('.btn-quantity.sub-minicart')
                            .addClass('btn-qty-disabled');
                    }
                    val -= 1;
                }
            }

            $input.val(val);
            $input.trigger('change');
            return false;
        });

        $('body').on('keyup', '#quantity', function () {
            var max = parseInt($(this).attr('data-product-limit'), 10);
            var available = parseInt($(this).attr('data-available'), 10);
            const min = parseInt($(this).attr('data-minimum'), 10);

            if (available < max) {
                max = available;
            }

            var value = $(this).val();
            value = value.replace(/^(0*)/, '');
            $(this).val(value);

            if ($(this).val() > max) {
                $(this).val(max);
            } else if ($(this).val() < min) {
                // let quantity selectors in set items have a minimum of 0
                const isSetItem = $(this).closest('.set-item').length > 0;

                $(this).val(isSetItem ? 0 : min);
            }
            if ($(this).val() % min !== 0 || $(this).val() < min) {
                $(this).val(min);
            }
        });
    },
    updateQuantitySelector: function () {
        $('body').on('click', '.quantity-selector-wrapper .quantity-selector .qty>button', function () {
            var $input = $(this)
                .parents('.quantity-selector')
                .find('input.quantity-select');
            var val = parseInt($input.val(), 10);

            const min = parseInt($input.attr('data-minimum'), 10);
            const isSetItem = $(this).closest('.set-item').length > 0;
            var available = parseInt($input.attr('data-available'), 10);
            var max = parseInt($input.attr('data-product-limit'), 10);

            if (available < max) {
                max = available;
            }

            if ($(this).hasClass('add')) {
                if (val < max) {
                    if (isSetItem && val === 0) {
                        val = min;
                    } else if (min !== 1) {
                        val += min;
                    } else {
                        if (val > 0) {
                            $('.quantity-selector')
                                .find('.btn-quantity.sub')
                                .removeClass('btn-qty-disabled');
                        }
                        val += 1;
                    }
                }
                // eslint-disable-next-line no-unused-expressions
                val === max ? $(this).addClass('btn-qty-disabled') : $(this).removeClass('btn-qty-disabled');
            } else if ($(this).hasClass('sub')) {
                // let quantity selectors in set items have a minimum of 0
                if (isSetItem && val === min) {
                    val = 0;
                }
                if (min !== 1 && val > min) {
                    val -= min;
                } else if (val > min) {
                    if (val === 2) {
                        $('.quantity-selector')
                            .find('.btn-quantity.sub')
                            .addClass('btn-qty-disabled');
                    }
                    if ($(this).siblings('.btn-quantity.add').hasClass('btn-qty-disabled')) {
                        $(this).siblings('.btn-quantity.add').removeClass('btn-qty-disabled');
                    }
                    val -= 1;
                }
            }

            $input.val(val);
            $input.trigger('change');
            return false;
        });

        $('body').on('keyup', '#quantity', function () {
            var max = parseInt($(this).attr('data-product-limit'), 10);
            var available = parseInt($(this).attr('data-available'), 10);
            const min = parseInt($(this).attr('data-minimum'), 10);

            if (available < max) {
                max = available;
            }

            var value = $(this).val();
            value = value.replace(/^(0*)/, '');
            $(this).val(value);

            if ($(this).val() > max) {
                $(this).val(max);
            } else if ($(this).val() < min) {
                // let quantity selectors in set items have a minimum of 0
                const isSetItem = $(this).closest('.set-item').length > 0;

                $(this).val(isSetItem ? 0 : min);
            }
            if ($(this).val() % min !== 0 || $(this).val() < min) {
                $(this).val(min);
            }
        });
    }
};
